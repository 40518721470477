<template>
  <v-container class="my-6 position-relative p-relative relative">
    <v-row>
      <v-col
        v-if="paymenterror && paymenterror.trim().length > 1"
        cols="12"
      >
        <v-alert
          prominent
          type="error"
        >
          <v-row align="center">
            <v-col class="grow">
              {{ paymenterror }}
            </v-col>
            <v-col class="shrink">
              <v-btn @click="paymenterror = null">
                {{ $t("Close") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
      <v-col
        v-if="!getCartProducts.length"
        cols="12"
      >
        <v-alert type="error">
          {{ $t("Votre panier est vide") }}
        </v-alert>
      </v-col>
      <v-col cols="12">
        <v-row v-if="getCartProducts.length">
          <v-col
            cols="12"
            md="8"
            lg="9"
            style="max-height: 750px;"
            class="overflow-y-auto"
          >
            <div
              v-for="(n, index) in getCartProducts"
              :key="index"
            >
              <base-card class="d-flex flex-wrap mb-6">
                <v-img
                  max-width="140"
                  :src="n.image"
                  alt=""
                />
                <div class="d-flex flex-column flex-grow-1 flex-wrap pa-4 mw-0">
                  <div class="d-flex justify-space-between w-100 mb-3">
                    <div>
                      <div class="d-flex">
                        <h4 class="font-600 text-truncate mb-4">
                          {{ n.name }}
                        </h4>
                      </div>
                      <div>
                        <p class="grey--text text--darken-1 mb-0">
                          &euro;{{ n.price_without_tax }} x {{ n.qty }} <span class="primary--text ms-2 font-600">&euro;{{ (n.total ).toFixed(2) }}</span>
                        </p>
                      </div>
                    </div>
                    <v-btn
                      icon
                      @click="removeCartx({...n, qty: 0})"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                  <div class="d-flex justify-end flex-grow-1 align-end">
                    <div class="d-flex align-center">
                      <v-btn
                        class=" rounded "
                        color="primary"
                        outlined
                        fab
                        x-small
                        tile
                        :disabled="n.qty === 0"
                        @click="removeCartx(n)"
                      >
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                      <div class="text-center mx-2">
                        {{ n.qty }}
                      </div>
                      <v-btn
                        class=" rounded"
                        outlined
                        fab
                        x-small
                        tile
                        color="primary"
                        @click="addCartx(n)"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </base-card>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="4"
            lg="3"
          >
            <base-card>
              <v-card-text>
                <div class="d-flex justify-space-between">
                  <p class="mb-0 grey--text text--darken-1">
                    {{ $t("Total") }}
                  </p>
                  <h4 class="font-600">
                    &euro;{{ cartTotal.toFixed(2) }}
                  </h4>
                </div>

                <!--<div class="d-flex align-center mb-4">
                  <h6 class="">
                    Additional Comments
                  </h6>
                  <v-chip
                    class="ma-2"
                    label
                    color="primary lighten-5"
                    small
                  >
                    <span class="primary&#45;&#45;text text-sm">Note</span>
                  </v-chip>
                </div>
                <v-textarea

                  outlined
                  hide-details=""
                  name="input-7-4"
                  label="Comments"
                  value=""
                />
                <v-divider class="my-4" />
                <v-text-field
                  label="Voucher"
                  outlined
                  dense
                  hide-details=""
                  class="mb-4"
                />
                <v-btn
                  color="primary"
                  outlined
                  class="text-capitalize font-600"
                  block
                >
                  Apply Voucher
                </v-btn>
                <v-divider class="my-3" />
                <h6 class="mb-4">
                  Shipping Estimates
                </h6>
                <p class="text-14 mb-1">
                  Country
                </p>
                <v-select
                  dense
                  class="mb-4"
                  :items="items"
                  label="Select Country"
                  outlined
                  hide-details
                />
                <p class="text-14 mb-1">
                  State
                </p>
                <v-select
                  dense
                  class="mb-4"
                  :items="items"
                  label="Select State"
                  outlined
                  hide-details
                />

                <p class="text-14 mb-1">
                  Zip Code
                </p>
                <v-text-field
                  label="3100"
                  outlined
                  dense
                  hide-details=""
                  class="mb-4"
                />
                <v-btn
                  color="primary"
                  outlined
                  class="text-capitalize font-600 mb-4"
                  block
                >
                  Calculate Shipping
                </v-btn>-->
              </v-card-text>
              <v-divider class="my-3" />
              <v-card-actions>
                <v-btn
                  to="/checkout"
                  color="primary"
                  class="text-capitalize font-600 mb-4"
                  block
                >
                  {{ $t("Commander") }}
                </v-btn>
              </v-card-actions>
            </base-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="isSnackbarBottomVisible"
    >
      {{ snackBarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="snackBarColor"
          text
          v-bind="attrs"
          @click="isSnackbarBottomVisible = false"
        >
          {{ $t("Fermer") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import Vue from 'vue'

  import { FormWizard, TabContent } from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
  import AddToCartButton from '../../components/products/ProductPrice'
  import { i18n } from '../../plugins/i18n'
  import { orderBy } from 'lodash/collection'
  import { VueMaskDirective } from 'v-mask'
  Vue.directive('mask', VueMaskDirective)

  export default {
    components: {
      FormWizard,
      TabContent,
    },
    data () {
      return {
        step: 0,
        paymentForm: null,
        cardNumber: null,
        cardName: null,
        cardCvx: null,
        cardExpirationDate: null,
        validAddressForm: false,
        loading: false,
        loading_points: false,
        loading_cities: false,
        create_new: false,
        security_tax: 0,
        commission_minimal: 0,
        donate: 0,
        usercountry: 0,
        association: null,
        system_transporters: [],
        shipment_methods: [],
        payment_methods: [],
        relay_points: [],
        packages: [],
        package: {},
        errors: {
          firstname: false,
          lastname: false,
          email: false,
          phone: false,
          country: false,
          address: false,
          zip: false,
          city: false,
        },

        salutationRules: [v => !!v || i18n.t('Requis.')],
        firstNameRules: [v => !!v || i18n.t('Le prénom est requis.')],
        lastNameRules: [v => !!v || i18n.t('Le nom de famille est requis.')],
        telRules: [v => !!v || i18n.t('Le numéro de téléphone est requis.')],
        addressRules: [v => !!v || i18n.t('L\'adresse est requise.')],
        transporterRules: [v => !!v || i18n.t('Un transport est requis')],
        cardCvxRules: [v => !!v || i18n.t('Le CVX est requis')],
        cardExpirationDateRules: [v => !!v || i18n.t('La date d\'expiration de la carte est requise')],
        cardNumberRules: [v => !!v || i18n.t('Le numéro de la carte est requis')],
        cardNameRules: [v => !!v || i18n.t('Le nom sur le panier est obligatoire')],
        items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
        radios: null,
        radiosTwo: null,
        cart: [
        ],
        order: {
          user: {
            country_code: null,
          },
          country_code: null,
          city: null,
          post_code: null,
        },
        addresses: [],
        deliveryaddress: [],
        snackBarMessage: '',
        snackBarColor: null,
        isSnackbarBottomVisible: false,
        settings: {},
        payment: {},
        paymenterror: null,
      }
    },
    computed: {
      ...mapGetters(['getCartProducts', 'unreadNotifications']),
      ...mapState({
        user: 'user',
        salutations: 'salutations',
        countries: 'countries',
        displayNotification: 'display_notification',
      }),
      cartTotal () {
        let total = 0
        this.getCartProducts.forEach(product => {
          total += product.price_without_tax * product.qty
        })
        return total
      },
      backButtonLabel () {
        return this.$t('Précédent')
      },
      nextButtonLabel () {
        if (this.step === 2) {
          return this.$t('Procéder au paiement')
        }
        return this.$t('Suivant')
      },
      finishButtonLabel () {
        return this.$t('Payer')
      },
    },
    mounted () {
      // this.loadMangopay()
    },
    created () {
      // this.paymenterror = this.$router.currentRoute.query.paymenterror || null
      // this.getSettings()
      /* if (this.$store.state.cart.id) {
        this.step = 1
        this.validateCart()
      } else {
        this.saveCart()
      } */
    },
    methods: {
      orderBy,
      ...mapActions(['addCart', 'removeCart']),
      ...mapMutations([
        'pushItem', // map `this.increment()` to `this.$store.commit('increment')`
        'removeItem', // map `this.increment()` to `this.$store.commit('increment')`
        // `mapMutations` also supports payloads:
        'setValue', // map `this.incrementBy(amount)` to `this.$store.commit('incrementBy', amount)`
      ]),
      loadMangopay () {
        const mangoScript = document.createElement('script')
        mangoScript.setAttribute('src', '/managopay-kit.min.js')
        document.head.appendChild(mangoScript)
      },
      getSettings () {
        // eslint-disable-next-line no-undef
        axios.get('/checkout_settings').then((response) => {
          if (response.data) {
            this.settings = response.data
            if (response.data.system_transporters) {
              this.system_transporters = response.data.system_transporters
            }
            if (response.data.association) {
              this.association = response.data.association
            }
            if (response.data.security_tax) {
              this.security_tax = response.data.security_tax
            }
            if (response.data.commission_minimal) {
              this.commission_minimal = response.data.commission_minimal
            }
            if (response.data.donate) {
              this.donate = response.data.donate
            }
            // eslint-disable-next-line no-undef
            mangoPay.cardRegistration.baseURL = this.settings.mangopay_base_url
            // eslint-disable-next-line no-undef
            mangoPay.cardRegistration.clientId = this.settings.mangopay_client_id
          }
        })
      },
      saveCart () {
        if (this.getCartProducts.length <= 0) {
          this.toast(this.$t('Votre panier est vide.'), 'red')
          return false
        } else {
          this.loading = true
          // eslint-disable-next-line no-undef
          axios.post('/carts', { id: this.$store.state.cart.id, products: this.getCartProducts.map((ele) => { return { id: ele.id, qty: ele.qty } }) })
            .then((response) => {
              this.loading = false
              if (response.data && response.data.error) {
                this.toast(this.$t(response.data.message), 'red')
              } else {
                var cart = this.$store.state.cart
                cart.id = response.data.id
                cart.total = response.data.total
                cart.user_id = response.data.user_id
                this.setValue({ type: 'cart', value: cart })
                localStorage.setItem('planete@cart', JSON.stringify(cart))
              }
            }).then(() => {
              this.loading = false
            })
        }
        return this.getCartProducts.length > 0
      },
      scrollToTop () {
        // This prevents the page from scrolling down to where it was previously.
        if ('scrollRestoration' in history) {
          history.scrollRestoration = 'manual'
        }
        // This is needed if the user scrolls down during page load and you want to make sure the page is scrolled to the top once it's fully loaded. This has Cross-browser support.
        window.scrollTo(0, 0)
      },
      async validateCart () {
        // if (this.loading) return false
        if (this.order.id) return true

        if (this.getCartProducts.length <= 0) {
          this.toast(this.$t('Votre panier est vide.'), 'red')
          return false
        } else {
          this.loading = true
          // eslint-disable-next-line no-undef
          var response = await axios.post('/checkout', { id: this.$store.state.cart.id })
          this.loading = false
          if (response.data && response.data.error) {
            this.toast(this.$t(response.data.message), 'red')
          } else {
            this.order = response.data
            this.order.packages = this.order.packages.map((el) => {
              return {
                ...el,
                points: el.transporter ? el.transporter.points || [] : [],
              }
            })
            this.step = this.order.step
            this.scrollToTop()
            if (!this.payment.id) {
              this.payAll()
            }

            return true
          }
        }
      },
      async validateAddress () {
        const isFormValid = this.$refs.addressForm.validate()
        if (!isFormValid) {
          this.toast(this.$t('Veuillez remplir tous les champs obligatoires.'), 'red')
          return false
        } else {
          this.loading = true
          // eslint-disable-next-line no-undef
          var response = await axios.post('/order/address', {
            id: this.order.id,
            user: this.order.user,
            packages: this.order.packages
              .map((e) => { return { transporter_id: e.transporter_id, point_id: e.point_id, id: e.id } }),
          })
          this.loading = false
          if (response.data && response.data.error) {
            this.toast(this.$t(response.data.message), 'red')
          } else {
            this.order = response.data
            this.order.packages = this.order.packages.map((el) => {
              return {
                ...el,
                points: el.transporter ? el.transporter.points || [] : [],
              }
            })
            this.step = this.order.step
            this.scrollToTop()
            return true
          }
        }
      },
      validPaymentForm () {
        const isFormValid = this.$refs.paymentForm.validate()
        if (!isFormValid) {
          this.toast(this.$t('Veuillez remplir tous les champs obligatoires.'), 'red')
          return false
        } else {
          this.loading = true
          // get manago payment token
          // eslint-disable-next-line no-undef
          /* var response = await axios.post(this.payment.card_registration_url, {
            accessKeyRef: this.payment.access_key,
            data: this.payment.preregistration_data,
            cardNumber: this.cardNumber,
            cardExpirationDate: this.cardExpirationDate,
            cardCvx: this.cardCvx,
          }) */

          var form = document.createElement('form')
          form.setAttribute('action', this.payment.card_registration_url)
          form.setAttribute('method', 'post')
          form.setAttribute('style', 'display: none')
          document.getElementsByTagName('body')[0].appendChild(form)

          // Add card registration data to the form
          form.appendChild(this.getInputElement('data', this.payment.preregistration_data))
          form.appendChild(this.getInputElement('accessKeyRef', this.payment.access_key))
          form.appendChild(this.getInputElement('cardNumber', this.cardNumber))
          form.appendChild(this.getInputElement('cardExpirationDate', this.cardExpirationDate))
          form.appendChild(this.getInputElement('cardCvx', this.cardCvx))
          form.appendChild(this.getInputElement('returnURL', this.payment.redirect_url))

          // Submit the form
          form.submit()
          this.loading = false
          if (response.data && response.data.error) {
            this.toast(this.$t(response.data.message), 'red')
          } else {
            this.payment.registration_data = response.data
            console.log(response.data)
            // go to payment page
            // return true
          }
        }
      },

      getInputElement (name, value) {
        var input = document.createElement('input')
        input.setAttribute('type', 'hidden')
        input.setAttribute('name', name)
        input.setAttribute('value', value)
        return input
      },
      toast (message, color) {
        this.isSnackbarBottomVisible = true
        this.snackBarColor = color
        this.snackBarMessage = message
      },
      removeCartx (item) {
        this.removeCart(item)
      },
      addCartx (item) {
        this.addCart(item)
      },

      addressChanged (event) {
        var item = this.getItem(this.addresses, 'id', event.target.value)

        this.order.address = item
      },
      countryChanged (event) {
        // console.log(event.target.value);
        var code = this.order.user.country_code
        // this.order.user.country_code = this.order.country_code
        this.resetTransporter(event)
        this.getCities(code)
        // this.relaypoints(0);
      },
      getCities (code) {
        var country = this.getItem(this.countries, 'code', code)
        var _this = this
        // this.order.address.city_id = null;

        if (!country.cities || country.cities.length === 0) {
          this.loading_cities = true
          axios.get('/countries/' + code).then((response) => {
            _this.loading_cities = false
            country.cities = response.data
            Vue.set(_this.countries, _this.getIndex(_this.countries, 'code', code), country)
          })
        }
        ;
        return country.cities || []
      },
      cityChanged (event) {
        // this.relaypoints(0);

      },
      zipChanged (event) {
        // this.relaypoints(0);
        this.order.packages.map((e) => { return { ...e, transporter_id: null } })
      },
      resetTransporter (event) {
        this.order.packages.map((e) => { return { ...e, transporter_id: null } })
      },
      transporterChanged (event, pkg) {
        this.getRelayPoints(pkg)
      },
      relaypoints (local = 0) {
        var transporter_id = this.order.transporter_id
        var country_id = this.order.user.country_code
        var city_id = this.order.user.city

        if (!country_id) {
          return []
        }
        if (!transporter_id) {
          return []
        }
        if (!this.order.user.zip || this.order.user.zip.length < 3) {
          return []
        }
        this.relay_points = []
        var that = this
        that.loading_points = true
        axios.get('/relay_points/' + transporter_id + '?&local=' + local,
                  { params: { country_id: country_id, city_id: city_id, zip: this.order.user.zip } })
          .then((response) => {
            this.relay_points = response.data
            that.loading_points = false
          }).catch(error => {
            that.loading_points = false
            alert(this.$t('Aucun point relais trouvé ici.'))
          })
      },
      getRelayPoints (pkg) {
        // pkg.points = this.system_transporters.find((e) => e.id === pkg.transporter_id).points || []

        var transporter_id = pkg.transporter_id
        var country_id = this.order.user.country_code
        var city_id = this.order.user.city

        if (!country_id) {
          return []
        }
        if (!transporter_id) {
          return []
        }
        if (!this.order.user.zip || this.order.user.zip.length < 3) {
          return []
        }
        this.relay_points = []
        var that = this
        that.loading_points = true
        axios.get('/transporters/relaypoints/' + transporter_id + '?&local=0',
                  { params: { country_id: country_id, city_id: city_id, zip: this.order.user.zip } })
          .then((response) => {
            pkg.points = response.data
            that.loading_points = false
          }).catch(error => {
            that.loading_points = false
            alert(this.$t('Aucun point relais trouvé ici.'))
          })
      },
      transporters (pk) {
        // console.log(pk.seller)

        var trans = [] // pk.seller.transporters || [];
        if (pk.seller.country_code === 'FR') {
          for (var i = 0; i < this.system_transporters.length; i++) {
            if (this.checkTariff(pk, this.system_transporters[i])) {
              trans.push(this.system_transporters[i])
            }
          }
        }
        /**/
        for (let i = 0; i < pk.seller.transporters.length; i++) {
          if (this.checkTariff(pk, pk.seller.transporters[i])) {
            trans.push(pk.seller.transporters[i])
          }
        }

        return trans
      },
      isSystemTransporter (transporter_id) {
        for (var i = 0; i < this.system_transporters.length; i++) {
          if (parseInt(transporter_id) === parseInt(this.system_transporters[i].id)) {
            return true
          }
        }
        return false
      },

      close () {
        $('#paymentbox').modal('hide')
        $('#paymentbox').find('.modal-body').html('')
      },
      pay (pkg) {
        /* $('#paymentbox').find('.modal-body').html('')
        $('#paymentbox').modal('show')
        this.loading = true
        axios.post('/card-registration', {
          currency: this.order.currency || 'EUR',
          cost: pkg.total,
          payment_type: '<?php echo e(\App\MangoPayApi::package); ?>',
          cardtype: this.order.card_type || '',
          returnurl: 'packages.pay',
          entity_id: pkg.id,
        }).then((response) => {
          this.loading = false
          $('#paymentbox').find('.modal-body').html(response.data)
        }).catch((error) => {
          this.loading = false
        }) */
      },
      payAll () {
        this.loading = true
        // eslint-disable-next-line no-undef
        axios.post('/card-registration', {
          currency: this.order.currency || 'EUR',
          amount: this.order.total,
          card_type: this.order.card_type || '',
          payment_type: this.settings.payment_type,
          returnurl: this.settings.payment_url/* 'cart.pay' */,
          order_id: this.order.id,
        }).then((response) => {
          this.loading = false
          this.payment = response.data
        }).catch((error) => {
          console.log(error)
          this.loading = false
          this.cardError = error
        })
      },
      getTax () {
        // if (this.order.donate) {
        //     t += this.donate;
        // }
        var security_fee = parseFloat(((this.security_tax / 100) * this.subTotal()).toFixed(2))
        if (security_fee < this.commission_minimal) { return this.commission_minimal }
        return security_fee// .toFixed(2);
      },
      getTotal () {
        var t = parseFloat(this.getTax()) + parseFloat(this.subTotal())

        // if (this.order.donate) {
        //     t += this.donate;
        // }

        return t ? t.toFixed(2) : t
      },
      subTotal () {
        var t = 0
        for (var i = 0; i < this.order.packages.length; i++) {
          t += parseFloat(this.getPackageTotal(this.order.packages[i]))
        }
        return t // t.toFixed(2);
      },
      checkTariff (pk, t) {
        var tas = t.tariffs || []

        for (var i = 0; i < tas.length; i++) {
          if ((tas[i].min_weight < pk.total_weight + 1) && (tas[i].max_weight > pk.total_weight - 1) && (tas[i].country_code === this.order.user.country_code)) {
            // return tas[i];
            return true
          }
        }
        // not found so we take the first price where min weight is less than the total weight
        /* for(var i=0;i<tas.length;i++){
            if(tas[i].min_weight < pk.total_weight+1  && tas[i].country_code == this.order.user.country_code){
                return tas[i];
            }
        } */
        return false
      },
      getTariffValue (pk) {
        console.log(pk)
        var tariff = this.getTariff(pk)
        if (this.isSystemTransporter(pk.transporter_id)) {
          return parseFloat(tariff.weight_price_ttc)
        }
        if (parseInt(pk.exportation) === 0) {
          if (tariff.weight_price_ttc) { return parseFloat(tariff.weight_price_ttc) }

          return parseFloat(tariff.weight_price)
        }

        return parseFloat(tariff.weight_price)
      },
      getTariff (pk) {
        if (!pk.transporter_id) {
          return { weight_price: 0, not_exist: true }
        }

        // using system transporters instead of seller transporter.
        var tas = this.getItem(pk.seller.transporters || [], 'id', pk.transporter_id).tariffs || []
        var tasys = this.getItem(this.system_transporters || [], 'id', pk.transporter_id).tariffs || []

        tasys.forEach((item) => {
          tas.push(item)
        })

        for (var i = 0; i < tas.length; i++) {
          // pk.total_weight = 2

          // console.log("country code",this.order.user.country_code);

          if ((tas[i].min_weight < pk.total_weight) && (tas[i].max_weight >= pk.total_weight) && (tas[i].country_code === this.order.user.country_code)) {
            return tas[i]
          }
        }

        // not found so we take the first price where min weight is less than the total weight
        // for (var i = 0; i < tas.length; i++) {
        //     if ((tas[i].min_weight < pk.total_weight + 1) && (tas[i].country_code === this.order.user.country_code)) {
        //         return tas[i];
        //     }
        // }

        return { weight_price: 0, weight_price_ttc: 0, not_exist: true }
      },
      getTva (pk) {
        return 19.0
      },
      getPackageTotal (pk) {
        var to = 0
        for (var i = 0; i < pk.items.length; i++) {
          to += parseFloat(pk.items[i].quantity * pk.items[i].price)
        }

        var tariffvalue = this.getTariffValue(pk)

        // if(parseInt(pk.exportation) === 0){
        //     if(tariff.weight_price_ttc)
        //         return (to + parseFloat(tariff.weight_price_ttc )).toFixed(2);
        //
        //     return (to + parseFloat(tariff.weight_price )).toFixed(2);
        // }
        //
        // return (to + parseFloat(tariff.weight_price)).toFixed(2);
        return (to + tariffvalue).toFixed(2)
        /* if (!pk.country_id || !pk.transporter_id) {
             return {weight_price: 0, not_exist: true};
         } */
        // return this.getItem(this.getItem(this.transporters(pk), 'id', pk.transporter_id).tariffs || [], "country_id", pk.country_id)
      },
      getItem (items, field, value) {
        for (var i = 0; i < items.length; i++) {
          if (items[i][field] == value) {
            return items[i]
          }
        }
        return false
      },

      getIndex (items, field, value) {
        for (var i = 0; i < items.length; i++) {
          if (items[i][field] == value) {
            return i
          }
        }
        return -1
      },

      removePackage (pk, index) {
        this.loading = true
        var _this = this
        axios.post('/orders/remove/package', { order_id: this.order.id, package_id: pk.id })
          .then((response) => {
            _this.loadiing = false
            _this.order.sub_total -= pk.total
            _this.order.packages.splice(index, 1)
            if (_this.order.packages.length == 0) {
              document.location.reload()
            }
          })
      },
      getTransporters (pkg) {
        pkg.seller.transporters
        return this.system_transporters
        var _this = this
        if (this.order.country_id) {
          _this.transporters = []
          axios.get('/countries/transporters/' + this.order.country_id)
            .then(response => {
              _this.transporters = response.data
              _this.getRelayPoints()
            })
        }
      },

    },
  }
</script>
